<template>
  <div class="mx-5 my-5">
    <v-row>
      <v-col md="12" class="d-flex" style="justify-content: space-between; align-items: center">
        <h2>{{ invoiceTypeLabel }}</h2>
        <v-switch @change="toggleInvoiceLock" v-if="!isNew" v-model="is_locked" color="green" :label="$t('Invoice Lock')"
          :dark="$store.state.isDarkMode"></v-switch>
      </v-col>
      <v-col md="3" class="py-0">
        <v-autocomplete :label="$t('Client Name')" outlined dense color="#757575" item-text="name" item-value="id"
          v-model="invoice.client_account_id" :items="clientAccounts" :loading="clientAccountLoading"
          v-debounce="clientAc"></v-autocomplete>
      </v-col>

      <v-col md="3" class="py-0">
        <v-menu ref="menu1" v-model="dateMenu" :close-on-content-click="false" transition="scale-transition" offset-y
          max-width="290px" min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :label="$t('date')" :dark="$store.state.isDarkMode" outlined dense persistent-hint
              prepend-icon="mdi-calendar" v-bind="attrs" v-on="on" v-model="invoiceDate"></v-text-field>
          </template>
          <v-date-picker v-model="invoiceDate" no-title @input="dateMenu = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col md="3" class="py-0">
        <v-menu ref="timeMenuRef" v-model="timeMenu" :close-on-content-click="false" :nudge-right="40"
          :return-value.sync="invoiceTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="invoiceTime" :label="$t('Time')" :dark="$store.state.isDarkMode"
              prepend-icon="mdi-clock-time-four-outline" readonly outlined dense v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-time-picker v-if="timeMenu" v-model="invoiceTime" full-width
            @click:minute="$refs.timeMenuRef.save(invoiceTime)"></v-time-picker>
        </v-menu>
      </v-col>
      <v-col md="3" class="py-0">
        <v-autocomplete :label="$t('cost center')" outlined dense color="#757575" item-text="name" item-value="id"
          v-model="invoice.cost_center_id" :items="costCenters" :loading="costCenterLoading"
          v-debounce="costCenterAc"></v-autocomplete>
      </v-col>
      <v-col md="3" class="py-0">
        <v-text-field :label="$t('Invoice Number')" outlined dense v-model="invoice.serial"></v-text-field>
      </v-col>
      <v-col md="3" class="py-0" style="display: flex; justify-content: center; align-items: flex-start">
        <v-radio-group :dark="$store.state.isDarkMode" row v-model="invoice.discount_type" class="mt-0">
          <template v-slot:label>
            <div style="font-size: 14px;">{{ $t('Discount') }}</div>
          </template>
          <v-radio value="PERCENTAGE"><template v-slot:label>
              <div style="font-size: 14px;">{{ $t('Percent') }}</div>
            </template></v-radio>
          <v-radio value="FIXED"><template v-slot:label>
              <div style="font-size: 14px;">{{ $t('Value') }}</div>
            </template></v-radio>
        </v-radio-group>
      </v-col>
      <v-col md="3" class="py-0">
        <v-text-field :label="$t('Discount')" :dark="$store.state.isDarkMode" type="number" outlined dense
          v-model="invoice.discount_value"></v-text-field>
      </v-col>
      <v-col md="3" class="py-0">
        <v-autocomplete :label="$t('Inventory')" outlined dense color="#757575" item-text="name" item-value="id" no-filter
          v-model="invoice.inventory_id" :loading="inventoriesLoading" :items="inventories" return-object
          v-debounce="inventoriesAc" @input="matchMaterialInventoriesHandler"></v-autocomplete>
      </v-col>
      <v-col v-if="invoice.table_view.length && invoice.table_view[14].value" md="3" >
        <v-autocomplete :label="$t('Tax Code')" outlined dense color="#757575" item-text="code" item-value="id"
          :items="taxes" v-model="invoice.tax_id" clearable @input="matchMaterialTax_IdHandler"></v-autocomplete>
      </v-col>
      <v-col md="3" class="py-0">
        <AutoCompleteField :label="$t('Tax Account')" outlined dense v-model="invoice.tax_account_id"
          endPoint="/accounting/account/auto-complete" returnObject clearable @input="matchMaterialTax_AccHandler" />
      </v-col>
      <v-col v-if="invoice.table_view.length && invoice.table_view[16].value" md="3" >
        <v-text-field :label="$t('Tax Percent')" v-model="invoice.tax_percent" type="number" outlined
          @input="matchMaterialTax_PercentHandler" dense></v-text-field>
      </v-col>
      <v-col md="3" class="py-0">
        <v-autocomplete :label="$t('Discount Account')" outlined dense color="#757575" item-text="name" item-value="id"
          v-model="invoice.discount_account_id" :items="disscountAccounts" :loading="disscountAccountLoading"
          v-debounce="disscountAccountAc"></v-autocomplete>
      </v-col>
      <v-col md="6" class="py-0">
        <v-text-field :label="$t('notes')" v-model="invoice.notes" outlined dense></v-text-field>
      </v-col>
      <v-col md="12">
        <v-row>
          <v-col md="3" class="py-0">
            <v-autocomplete :label="$t('Payment Method')" :dark="$store.state.isDarkMode" outlined dense color="#757575"
              item-text="label" item-value="value" no-filter :items="paymentMethods"
              v-model="invoice.payment_method"></v-autocomplete>
          </v-col>

          <v-col md="3" class="py-0" v-if="invoice.payment_method == 'CASH'">
            <v-autocomplete :dark="$store.state.isDarkMode" :label="$t('hr.loan.cash')" outlined dense color="#757575"
              item-text="name" item-value="id" v-model="invoice.cash_account_id" :items="cashAccounts"
              :loading="cashAccountLoading" v-debounce="cashAc"></v-autocomplete>
          </v-col>
          <v-col md="3" class="py-0" v-if="invoice.payment_method == 'CASH'">
            <v-text-field :dark="$store.state.isDarkMode" :label="$t('Paid Value')" v-model.number="invoice.paid_amount"
              outlined type="number" dense></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-card v-if="invoice.payment_method == 'INSTALLMENT'" md="12" class="my-5 mx-5">
        <v-card-title class="d-flex justify-center">
          <h2>
            {{ $t('Distribution Installments') }}
          </h2>
        </v-card-title>
        <v-card-text>
          <v-container id="buffet-table" fluid tag="section">
            <v-simple-table :dark="$store.state.isDarkMode" style=" width: max-content;">
              <tr>
                <th id="idAutoComp">{{ $t('due-date') }}</th>
                <th id="idAutoComp">{{ $t('hr.loan.trans-value') }}</th>
                <th id="idAutoComp">{{ $t('note') }}</th>
                <th></th>
              </tr>
              <v-fade-transition mode="out-in" :group="true" tag="tbody">
                <tr v-for="(el, ix) in installments" :key="ix">
                  <td>
                    <v-text-field full-width dense hide-details type="date" flat color="#757575" v-model="el.date"
                      @keydown.enter="ToNextInput"></v-text-field>
                  </td>
                  <td>
                    <v-text-field full-width dense hide-details type="number" flat color="#757575" v-model="el.amount"
                      @keydown.enter="ToNextInput"></v-text-field>
                  </td>
                  <td>
                    <v-text-field @keydown.enter="addNewInstallment" full-width type="text" dense hide-details flat
                      color="#757575" v-model="el.note"></v-text-field>
                  </td>
                  <td>
                    <v-icon @click="deleteRowI(ix)" color="red"
                      style="border: 1px solid red; border-radius: 50%">mdi-minus</v-icon>
                  </td>
                </tr>
              </v-fade-transition>
            </v-simple-table>
          </v-container>
        </v-card-text>
      </v-card>
      <v-card style="padding: 1%; ">
        <v-container id="buffet-table" fluid tag="section">
          <v-simple-table :dark="$store.state.isDarkMode" style=" width: max-content;">
            <tr>
              <th v-if="invoice.table_view[0].value" style="padding-left: 100px;  padding-right: 100px;">{{ $t('Material')
              }}</th>
              <th v-if="invoice.table_view[1].value" id="idAutoComp">{{ $t('Type') }}</th>
              <th v-if="invoice.table_view[2].value" id="idPrice">{{ $t('quantity') }}</th>
              <th v-if="invoice.table_view[3].value">{{ $t('Unit') }}</th>
              <th v-if="invoice.table_view[4].value" id="idPrice">{{ $t('price') }}</th>
              <th v-if="invoice.table_view[5].value" id="idPrice">{{ $t('sum') }}</th>
              <th v-if="invoice.table_view[6].value" id="idPrice">{{ $t('Discount') }}</th>
              <th v-if="invoice.table_view[7].value" id="idPrice">{{ $t('add') }}</th>
              <th v-if="invoice.table_view[8].value" id="idPrice">{{ $t('Net') }}</th>
              <th v-if="invoice.table_view[14].value" id="idAutoComp">{{ $t('Tax Code') }}</th>
              <th v-if="invoice.table_view[16].value" id="idPrice">{{ $t('Tax Percent') }}</th>
              <th v-if="invoice.table_view[17].value" id="idPrice">{{ $t('Included') }}</th>
              <th v-if="invoice.table_view[18].value" id="idPrice">{{ $t('Tax Value') }}</th>
              <th v-if="invoice.table_view[15].value" id="idAutoComp">{{ $t('Tax Account') }}</th>
              <th v-if="invoice.table_view[9].value" id="idAutoComp">{{ $t('Inventory') }}</th>
              <th v-if="invoice.table_view[10].value" id="idAutoComp">{{ $t('Corresponding Account') }}</th>
              <th v-if="invoice.table_view[11].value" id="idAutoComp">{{ $t('Beneficiary') }}</th>
              <th v-if="invoice.table_view[12].value" id="idAutoComp">{{ $t('Beneficiary Percentage') }}</th>
              <th v-if="invoice.table_view[13].value" id="idAutoComp">{{ $t('explain') }}</th>
              <th></th>
            </tr>
            <v-fade-transition mode="out-in" :group="true" tag="tbody">
              <tr v-for="(material, ix) in invoice.materials" :key="ix">
                <td>
                  <AutoCompleteField label="" v-model="material.material_id"
                    endPoint="/inventories/materials/auto-complete" returnObject @input="onMaterialInput(material)" />
                </td>
                <td>
                  <v-select :items="material.type" v-model="material.type_id" item-text="name" item-value="id">
                  </v-select>
                </td>
                <td>
                  <v-text-field full-width dense hide-details flat type="number" color="#757575"
                    @keydown.enter="ToNextInput" v-model.number="material.quantity"></v-text-field>
                </td>
                <td>{{ $t('Piece') }}</td>
                <td>
                  <v-text-field full-width dense hide-details type="number" flat color="#757575"
                    @keydown.enter="ToNextInput" v-model.number="material.price"></v-text-field>
                </td>
                <td>{{ material.quantity * material.price }}</td>
                <td>
                  <v-text-field full-width dense hide-details type="number" flat color="#757575"
                    @keydown.enter="ToNextInput" v-model.number="material.discount_value"></v-text-field>
                </td>
                <td>
                  <v-text-field full-width dense hide-details type="number" flat color="#757575"
                    @keydown.enter="ToNextInput" v-model.number="material.increase_value"></v-text-field>
                </td>
                <td>
                  {{
                    material.quantity * material.price -
                    material.discount_value +
                    material.increase_value
                  }}
                </td>
                <td v-if="invoice.table_view[14].value">
                  <v-autocomplete full-width dense hide-details flat :items="taxes" item-text="code" item-value="id"
                    v-model="material.tax_id"></v-autocomplete>
                </td>
                <td v-if="invoice.table_view[16].value">
                  <v-text-field full-width dense hide-details type="number" v-model="material.tax_percent"
                    @keydown.enter="ToNextInput" flat></v-text-field>
                </td>
                <td v-if="invoice.table_view[17].value" class="justify-center">
                  <v-checkbox :dark="$store.state.isDarkMode" v-model="material.is_tax_included" :true-value="1"
                    :false-value="0" class="mx-4 mb-4" hide-details></v-checkbox>
                </td>
                <td v-if="invoice.table_view[18].value"> {{ totalTaxI(material) }} </td>
                <td v-if="invoice.table_view[15].value">
                  <AutoCompleteField label="" dense v-model="material.tax_account_id"
                    endPoint="/accounting/account/auto-complete" returnObject @input="ToNextInput" />
                </td>
                <td>
                  <AutoCompleteField label="" v-model="material.inventory_id" endPoint="/inventories/auto-complete"
                    returnObject @input="ToNextInput" />
                </td>
                <td>
                  <AutoCompleteField label="" v-model="material.account_id" endPoint="/accounting/account/auto-complete"
                    returnObject @input="ToNextInput" />
                </td>
                <td>
                  <AutoCompleteField label="" v-model="material.beneficiary_account_id"
                    endPoint="/accounting/account/auto-complete" returnObject @input="ToNextInput" clearable />
                </td>
                <td>
                  <v-text-field full-width dense hide-details type="number" flat color="#757575"
                    @keydown.enter="ToNextInput" v-model.number="material.beneficiary_percentage"></v-text-field>
                </td>
                <td>
                  <v-text-field full-width dense hide-details flat color="#757575" @keydown.enter="addNewMaterial"
                    v-model="material.desc"></v-text-field>
                </td>
                <td>
                  <v-icon @click="deleteRow(ix)" color="red"
                    style="border: 1px solid red; border-radius: 50%">mdi-minus</v-icon>
                </td>
              </tr>
            </v-fade-transition>
          </v-simple-table>
        </v-container>
      </v-card>
      <v-col md="9">
        <v-btn @click="newExpense" class="my-1" color="green" dark>{{ $t('Add Account') }}</v-btn>
        <v-row v-if="invoice.invoice_expenses.length">
          <v-col md="3"></v-col>
          <v-col md="3">
            <h3 style="text-align: center" class="mb-2">{{ $t('Expenses Account') }}</h3>
          </v-col>
          <v-col md="3">
            <h3 style="text-align: center" class="mb-2">{{ $t('Corresponding Account') }}</h3>
          </v-col>
          <v-col md="3">
            <h3 style="text-align: center" class="mb-2">{{ $t('Value') }}</h3>
          </v-col>
          <!--  -->
          <v-row class="py-0" v-for="(expense, ix) in invoice.invoice_expenses" :key="ix">
            <v-col md="3" class="pt-0">
              <v-btn color="red" dark @click="deleteInvoiceExpense(ix)">
                <v-icon size="20" dark>mdi-delete</v-icon>
                {{ $t('Delete Account') }}
              </v-btn>
            </v-col>
            <v-col md="3" class="py-0">
              <AutoCompleteField label="" v-model="expense.expense_account" endPoint="/accounting/account/auto-complete"
                outlined />
            </v-col>
            <v-col md="3" class="py-0">
              <AutoCompleteField label="" v-model="expense.related_account" endPoint="/accounting/account/auto-complete"
                outlined />
            </v-col>
            <v-col md="3" class="py-0">
              <v-text-field type="number" v-model.number="expense.value" outlined dense></v-text-field>
            </v-col>
          </v-row>
          <!--  -->
        </v-row>
        <v-row>
          <v-col md="3"></v-col>
          <v-col md="3"></v-col>
          <v-col md="3"></v-col>
          <v-col md="3" class="pl-0" v-if="invoice.invoice_expenses.length">
            <v-text-field :value="invoice.invoice_expenses.reduce(
              (prev, curr) => prev + curr.value,
              0
            )
              " readonly outlined dense :label="$t('Total Expenses')"></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="3" style="padding-top: 94px">
        <v-text-field :value="invoiceTotalValue" outlined dense :label="$t('Invoice Value')"></v-text-field>
        <v-text-field :value="totalQuantities" readonly outlined dense :label="$t('Total Quantities')"></v-text-field>
        <v-text-field :value="taxTotalValue" readonly outlined dense :label="$t('Tax')"></v-text-field>
        <v-text-field :value="invoiceAfterTaxValue()" readonly outlined dense :label="$t('Invoice Value After Tax')"></v-text-field>
      </v-col>
      <v-col md="4">
        <v-btn color="blue" :disabled="!isNew && is_locked" block @click="createNewInvoice" :loading="submitting">{{ isNew
          ? $t('add') : $t('edit') }}</v-btn>
      </v-col>
    </v-row>
  </div>
</template>
  
<script>
import axios from "axios";
import AutoCompleteField from "../invoices/AutoCompleteField.vue";
import i18n from "@/i18n";

export default {
  components: { AutoCompleteField },
  props: {
    isNew: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      installments: [],
      is_locked: false,
      timeMenu: false,
      timeMenuRef: null,
      invoiceDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      invoiceTime: `${String(new Date().getHours()).length == 1
        ? `0${new Date().getHours()}`
        : new Date().getHours()
        }:${String(new Date().getMinutes()).length == 1
          ? `0${new Date().getMinutes()}`
          : new Date().getMinutes()
        }`,
      invoiceTypeLabel: null,
      fetchingMetadata: false,
      invoiceMetadata: null,
      disscountType: "PERCENTAGE",
      paymentMethods: [
        {
          label: i18n.t('Cash'),
          value: "CASH",
        },
        {
          label: i18n.t('Credit'),
          value: "CREDIT",
        },
        {
          label: i18n.t('Installment'),
          value: "INSTALLMENT",
        },
      ],
      dateMenu: false,
      invoice: {
        client_account_id: null,
        date: null,
        serial: null,
        notes: null,
        discount_value: null,
        discount_type: null,
        invoice_type_id: null,
        cost_center_id: null,
        inventory_id: null,
        payment_method: null,
        cash_account_id: null,
        discount_account_id: null,
        paid_amount: null,
        is_locked: false,
        invoice_expenses: [],
        materials: [],
        total_amount: 0,
        tax_id: null,
        tax_account_id: null,
        tax_percent: 0,
        table_view: [],
      },
      costCenterLoading: false,
      costCenters: [],
      inventoriesLoading: false,
      inventories: [],
      clientAccounts: [],
      clientAccountLoading: false,
      cashAccounts: [],
      cashAccountLoading: false,
      disscountAccounts: [],
      disscountAccountLoading: false,
      submitting: false,
      sid: null,
      taxes: [],
    };
  },
  computed: {
    totalQuantities() {
      let val = 0;

      if (this.invoice.materials.length) {
        val = this.invoice.materials.reduce(
          (prev, curr) => prev + curr.quantity,
          0
        );
      }
      return val;
    },
    taxTotalValue() {
      let val = 0;
      if (this.invoice.materials.length) {
        this.invoice.materials.forEach(el => {
          if (el.tax_value) {
            val += el.tax_value;
          }
        });
      }
     if(val) return parseFloat(val.toFixed(2));
     else return 0;
    },
    invoiceTotalValue() {
      let val = 0;
      if (this.invoice.materials.length) {
        val = this.invoice.materials.reduce(
          (prev, curr) =>
            prev +
            (curr.quantity * curr.price - curr.discount_value) +
            curr.increase_value,
          0
        );
        if (
          this.invoice.discount_type === "FIXED" &&
          this.invoice.discount_value
        ) {
          val -= this.invoice.discount_value;
        } else if (
          this.invoice.discount_type === "PERCENTAGE" &&
          this.invoice.discount_value
        ) {
          val -= val * (this.invoice.discount_value / 100);
        }
      }
      return val;
    },
  },
  methods: {
    invoiceAfterTaxValue() {
      let val = 0;
      if (this.invoice.materials.length) {
        this.invoice.materials.forEach(el => {
            val += (el.quantity * el.price - el.discount_value) + el.increase_value;
        });
        if (
          this.invoice.discount_type === "FIXED" &&
          this.invoice.discount_value
        ) {
          val -= this.invoice.discount_value;
        } else if (
          this.invoice.discount_type === "PERCENTAGE" &&
          this.invoice.discount_value
        ) {
          val -= val * (this.invoice.discount_value / 100);
        }
      }
      if (this.invoice.materials.length) {
        this.invoice.materials.forEach(el => {
          if (!el.is_tax_included) {
            if (el.tax_value) {
            val += el.tax_value;
          }
          }
        });
      }
      if(val) return parseFloat(val.toFixed(2));
     else return 0;
    },
    deleteRowI(index) {
      if (this.installments.length == 1) return;
      this.installments.splice(index, 1);
    },
    addNewInstallment(focus = true) {
      this.installments.push({
        date: null,
        amount: null,
        note: null,

      });
    },
    totalTaxI(mat) {
      let total = (mat.quantity * mat.price) - mat.discount_value + mat.increase_value;
      if (mat.is_tax_included) {
        mat.tax_value = (total * mat.tax_percent) / (100 + parseFloat(mat.tax_percent));
      }
      else {
        mat.tax_value = total * mat.tax_percent / 100
      }
      if (mat.tax_value) return parseFloat(mat.tax_value.toFixed(2));
      else return 0;
    },
    matchMaterialTax_IdHandler() {
      this.taxes.forEach((el) => {
        if (el.id == this.invoice.tax_id) {
          this.invoice.tax_percent = el.percent;
          this.matchMaterialTax_PercentHandler();
        }
      });
      this.invoice.materials.forEach((mat) => {
        mat.tax_id = this.invoice.tax_id;
      });
    },
    matchMaterialTax_PercentHandler() {
      this.invoice.materials.forEach((mat) => {
        mat.tax_percent = this.invoice.tax_percent;
      });
    },
    matchMaterialTax_AccHandler() {
      this.invoice.materials.forEach((mat) => {
        mat.tax_account_id = this.invoice.tax_account_id;
      });
    },
    deleteInvoiceExpense(ix) {
      this.invoice.invoice_expenses.splice(ix, 1);
    },
    async toggleInvoiceLock() {
      await axios.patch(
        `/inventories/invoices/${this.$route.params.id}/toggle-lock`,
        {
          is_locked: this.is_locked,
        }
      );
    },

    matchMaterialInventoriesHandler() {
      this.invoice.materials.forEach((mat) => {
        mat.inventory_id = this.invoice.inventory_id;
      });
    },
    matchMaterialRelatedAccountHandler() {
      this.invoice.materials.forEach((mat) => {
        mat.account_id = this.invoiceMetadata.invoiceType.related_account;
      });
    },
    async createNewInvoice() {
      try {
        this.submitting = true;

        await axios.post("/inventories/invoices", {
          ...this.invoice,
          tax_account_id: this.invoice.tax_account_id?.id,
          discount_type: this.invoice.discount_type,
          inventory_id: this.invoice.inventory_id?.id,
          date: `${this.invoiceDate} ${this.invoiceTime}`,
          total_amount: this.invoiceTotalValue,
          cash_account_id:
            this.invoice.payment_method == "CREDIT"
              ? null
              : this.invoice.cash_account_id,
          paid_amount:
            this.invoice.payment_method == "CREDIT"
              ? null
              : this.invoice.paid_amount,
          materials: this.invoice.materials.map((mat) => ({
            ...mat,
            tax_account_id: mat.tax_account_id?.id,
            inventory_id: mat.inventory_id?.id,
            material_id: mat.material_id?.id,
            type: mat.material_id?.type,
            beneficiary_account_id: mat.beneficiary_account_id?.id,
            account_id: mat.account_id?.id,
          })),
          installments: this.installments.map((mat) => ({
            ...mat,
            date: mat.date,
            amount: mat.amount,
            note: mat.note,

          })),
          student_id: this.sid,
          invoice_order_id: this.$route.params.id,
        });

        this.$router.go(-1);
      } finally {
        this.submitting = false;
      }
    },
    invoiceTypeFormatter(type) {
      return [
        {
          label: "فاتورة مشتريات",
          value: "PURCHASES",
        },
        {
          label: "فاتورة مبيعات",
          value: "SALES",
        },
        {
          label: "فاتورة مرتجع المشتريات",
          value: "PURCHASE_REFUNDS",
        },
        {
          label: "فاتورة مرتجع المبيعات",
          value: "SALES_REFUNDS",
        },
      ].find((el) => el.value == type).label;
    },
    async cashAc(value) {
      try {
        if (!value) return;
        this.cashAccountLoading = true;
        const searchResult = await axios.get(
          "accounting/account/auto-complete",
          {
            params: {
              query: value,
            },
          }
        );
        this.cashAccounts = searchResult.data.data;
      } catch (err) {
      } finally {
        this.cashAccountLoading = false;
      }
    },
    async clientAc(value) {
      try {
        if (!value) return;
        this.clientAccountLoading = true;
        const searchResult = await axios.get(
          "accounting/account/auto-complete",
          {
            params: {
              query: value,
            },
          }
        );
        this.clientAccounts = searchResult.data.data;
      } catch (err) {
      } finally {
        this.clientAccountLoading = false;
      }
    },
    async inventoriesAc(value) {
      if (!value) return;
      try {
        this.inventoriesLoading = true;
        const searchResult = await axios.get("/inventories/auto-complete", {
          params: {
            query: value,
          },
        });
        this.inventories = searchResult.data.data;
      } catch (err) {
      } finally {
        this.inventoriesLoading = false;
      }
    },
    async costCenterAc(value) {
      try {
        if (!value) return;
        this.costCenterLoading = true;
        const searchResult = await axios.get(
          "accounting/cost-center/auto-complete",
          {
            params: {
              query: value,
            },
          }
        );
        this.costCenters = searchResult.data.data;
      } catch (err) {
      } finally {
        this.costCenterLoading = false;
      }
    },
    async disscountAccountAc(value) {
      try {
        if (!value) return;
        this.disscountAccountLoading = true;
        const searchResult = await axios.get(
          "accounting/account/auto-complete",
          {
            params: {
              query: value,
            },
          }
        );
        this.disscountAccounts = searchResult.data.data;
      } catch (err) {
      } finally {
        this.disscountAccountLoading = false;
      }
    },
    newExpense() {
      this.invoice.invoice_expenses.push({
        expense_account_id: null,
        related_account_id: null,
        value: null,
      });
    },
    deleteRow(index) {
      if (this.invoice.materials.length == 1) return;
      this.invoice.materials.splice(index, 1);
    },
    async onMaterialInput(material) {
      this.ToNextInput();
      console.log(material, "material");
      const result = await axios.get(
        `/inventories/materials/${material.material_id.id}?invoice_type=${this.invoice.invoice_type_id}`
      );
      const resultMetadata = await axios.get(
        `/inventories/invoices/invoice-type/${this.invoice.invoice_type_id}`
      );
      console.log(resultMetadata, "META");
      const metadataTemp = resultMetadata.data.data.invoiceType;

      const dataTemp = result.data.data;
      const invoiceType = resultMetadata.data.data.invoiceType.type;

      if (invoiceType == "PURCHASES") {
        if (dataTemp.purchases_account) {
          material.account_id = dataTemp.purchases_account;
        } else {
          material.account_id = metadataTemp.related_account;
        }
      } else if (invoiceType == "SALES") {
        if (dataTemp.sales_account) {
          material.account_id = dataTemp.sales_account;
        } else {
          material.account_id = metadataTemp.related_account;
        }
      } else if (invoiceType == "PURCHASE_REFUNDS") {
        if (dataTemp.purchases_refund_account) {
          material.account_id = dataTemp.purchases_refund_account;
        } else {
          material.account_id = metadataTemp.related_account;
        }
      }
      // SALES_REFUNDS
      else {
        if (dataTemp.sales_account) {
          material.account_id = dataTemp.sales_account;
        } else {
          material.account_id = metadataTemp.related_account;
        }
      }
      material.type = dataTemp.values;
      material.price = dataTemp.price;
    },
    addNewMaterial(focus = true) {
      this.invoice.materials.push({
        material_id: null,
        type: null,
        type_id: null,
        quantity: null,
        price: null,
        discount_value: null,
        increase_value: null,
        account_id: null,
        beneficiary_account_id: null,
        beneficiary_percentage: null,
        desc: null,
        tax_value: 0,
        inventory_id: null,
      });
      this.matchMaterialInventoriesHandler();
      this.matchMaterialRelatedAccountHandler();
      if (focus) {
        setTimeout(() => {
          this.ToNextInput();
        }, 0);
      }
    },
    ToNextInput() {
      const inputs = Array.prototype.slice.call(
        document.querySelectorAll("table input:not([type=hidden])")
      );
      const currInput = document.activeElement;
      const currInputIndex = inputs.indexOf(currInput);
      const nextinputIndex = currInputIndex + 1;
      const input = inputs[nextinputIndex];
      input.focus();
    },
  },
  async created() {

    if (!this.installments) {
      this.addNewInstallment();
    }

    const result = await axios.get(
      `/inventories/invoice-request/to-invoice/${this.$route.params.id}`
    );
    const dataTemp = result.data.data;

    this.invoice.client_account_id = dataTemp.client_account_id;
    this.clientAccounts.push(dataTemp.client_account);
    this.invoice.invoice_expenses = dataTemp.invoice_expenses.map((el) => ({
      expense_account: el.expense_account,
      related_account: el.related_account,
      expense_account_id: el.expense_account.id,
      related_account_id: el.related_account.id,
      value: el.value,
    }));
    this.invoice.materials = dataTemp.invoice_materials.map((el) => ({
      ...el,
      material_id: el.material,
      inventory_id: el.inventory,
      account_id: el.account,
      beneficiary_account_id: el.beneficiary_account,
    }));
    this.installments = dataTemp.installments.map((el) => ({
      ...el,
      date: el.date,
      amount: el.amount,
      note: el.note,
    }));
    this.invoice.discount_type = dataTemp.discount_type;
    this.invoice.discount_value = dataTemp.discount_value;
    this.invoice.invoice_type_id = dataTemp.invoice_type_id;
    this.invoice.serial = dataTemp.serial;
    this.invoice.paid_amount = dataTemp.paid_amount;
    this.invoice.payment_method = dataTemp.payment_method;
    this.costCenters.push(dataTemp.cost_center);
    this.invoice.cost_center_id = dataTemp.cost_center_id;
    this.inventories.push(dataTemp.inventory);
    this.invoice.inventory_id = dataTemp.inventory;

    this.cashAccounts.push(dataTemp.cash_account);
    this.invoice.cash_account_id = dataTemp.cash_account_id;

    this.disscountAccounts.push(dataTemp.discount_account);
    this.invoice.discount_account_id = dataTemp.discount_account_id;
    this.invoice.table_view = dataTemp.invoice_type.table_view;
    this.invoice.tax_account_id = dataTemp.invoice_type.tax_account;
    this.invoice.tax_percent = dataTemp.invoice_type.tax_percent;
    this.invoice.tax_id = dataTemp.invoice_type.tax_id;

    this.invoiceTypeLabel =
      this.invoiceTypeFormatter(dataTemp.invoice_type.type) +
      " - " +
      dataTemp.invoice_type.name;
    console.log(dataTemp);
    console.log(this.invoice);

    let params = { paginated: 'false', };
    if (this.returnAbility('tax:index')) {
      let res = await axios.get(`/accounting/tax`, { params });
      this.taxes = res.data.data;
    }


  },


};
</script>
  
<style scoped lang="scss">
.add-user__chip1 {
  border: 1px solid #757575;
  border-radius: 10px;
  margin: 5px 0;
  width: 100%;
}

.cardd {
  border: 1px solid #a2a2a2;
  width: 100%;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 4px 4px;
}

.cardd .preview-img {
  width: 100%;
}

table {
  border-collapse: collapse;
  table-layout: fixed;
}

.tableId {
  border-radius: 10%;
}

td,
th {
  padding: 0px 10px;
  border-collapse: collapse;
  border-radius: 10%;
}

table,
th,
td {
  border: 1px solid #000;
  border-collapse: collapse;
  border-radius: 10%;
}

#idAutoComp {
  padding-left: 70px;
  padding-right: 70px;
}

#idPrice {
  padding-left: 40px;
  padding-right: 40px;
}

#buffet-table {
  border: 1px solid rgb(216 216 221 / 53%);
  font-size: 1rem;
}

#buffet-table th {
  color: #909399;
  border: 1px solid rgb(216 216 221 / 53%);
  font-size: 1rem;
  text-align: center;
  width: max-content;

}

#buffet-table tr {
  transition: 0.5s;
}

#buffet-table td {
  border: 1px solid rgb(216 216 221 / 53%);
  text-align: center;
  font-size: 1.1rem;
  width: max-content;
}</style>
